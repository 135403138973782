/**
 * This class will store the events and their listeners.
 * When an event is triggered, it will call all the listeners of that event.
 * if there is no listener for that event, it will store the event and will call the listener when it is added.
 * This class will be used to communicate between different modules.
 */
class MessageBroker {
    constructor() {
        this.events = {};
        this.bufferedEvents = {};
    }
    waitFor(events, callback) {
        let eventsCount = events.length;
        let eventsData = {};
        events.forEach((event) => {
            this.on(event, (data) => {
                eventsData[event] = data;
                eventsCount--;
                if (eventsCount === 0) {
                    callback(eventsData);
                }
            });
        });
    }
    on(event, listener) {
        // check if there is a buffered events for the event
        if (this.bufferedEvents[event]) {
            // call the listener with the buffered data
            this.bufferedEvents[event].events.forEach((eventData) => {
                listener(eventData);
            });
            // clear the buffered events
            this.bufferedEvents[event].events = [];
        }
        // if the event is not registered, then add the event to the events object
        if (!this.events[event]) {
            this.events[event] = [];
        }
        // add the listener to the event
        this.events[event].push(listener);
    }
    fireEvent(event, data) {
        if (this.events[event]) {
            this.events[event].forEach((listener) => {
                listener(data);
            });
        }
        else {
            if (!this.bufferedEvents[event]) {
                this.bufferedEvents[event] = {
                    events: [],
                };
            }
            this.bufferedEvents[event].events.push(data);
        }
    }
}
window.WizardMessageBroker = new MessageBroker();
